<template>
  <ul
    class="accordion accordion__bottom-line"
  >
    <slot />
  </ul>
</template>

<style scoped>
.accordion__bottom-line {
  border-bottom: 0.0625rem solid var(--colour-border-light);
}
</style>
