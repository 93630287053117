<template>
  <div>
    <div
      class="preference__navigation preference__title-button"
    >
      <span
        class="preference__back-link text text--body-s"
        @click="onBackClicked"
      >
        Back
      </span>
      <Button
        type="tertriary"
        small
        icon-only
        :ssr-icon="ellipsis"
        @click="onInfoClicked"
      />
    </div>
    <div class="preference">
      <div class="preference__title">
        {{ preferenceValue?.preferenceName }}
      </div>
      <p
        class="preference__description"
      >
        {{ preferenceValue?.preferenceDescription }}
      </p>
      <span
        class="preference__node-name"
      >
        {{ preferenceValue?.hierarchyNodeName }} ({{ preferenceValue?.hierarchyNodeCode }})
      </span>
      <div
        class="preference__values"
      >
        <div
          class="preference__tabs-wrapper"
        >
          <tabs
            :tabs="tabs"
            :selected-tab="activeTab"
            class="preference__tabs"
            @tabClicked="changeTab"
          />
          <div
            v-if="preferenceValue?.inherited"
          >
            <span
              class="preference__inherited"
            >
              Inherited
            </span>
            <div>
              <span>
                Values are inherited from
                {{ preferenceValue.inherited.hierarchyNodeName }}
                ({{ preferenceValue.inherited.hierarchyNodeCode }})
              </span>
            </div>
          </div>
          <div class="preference__values-wrapper">
            <div
              v-if="activeTab.name === tabs[0].name"
              class="preference__tab-content"
            >
              <preference-value
                :preference-value-d-t-o="preferenceValueDTO(
                  preferenceValue?.preferenceType.type,
                  preferenceValue?.preferenceType.baseType,
                  !!preferenceValue?.inherited,
                  (preferenceValue?.startTime && preferenceValue?.allValues[preferenceValue?.startTime])
                    || preferenceValue?.inherited && preferenceValue?.allValues[preferenceValue?.startTime]
                    || [],
                  preferenceValue?.startTime,
                  null,
                  null,
                  preferenceValue?.preferenceType.customEditor,
                  preferenceValue?.preferenceName,
                )"
                :can-edit-preference-values="canEditPreferenceValues && !preferenceValue?.preferenceType.config"
                @saveRequest="onSaveRequest"
                @hasChange="updateHasChanges"
              />
              <div
                v-if="canEditPreferenceValues && preferenceValue?.preferenceType.config"
              >
                Editing for this type is not yet available. It can still be done in the old solution (DFPAdmin).
              </div>
            </div>
            <div
              v-if="activeTab.name === tabs[1].name"
              class="preference__tab-content"
            >
              <span
                v-if="preferenceValue?.futureStartTimes"
                class="preference-future-values__title"
              >
                Scheduled changes
              </span>
              <span
                v-else
              >
                Changes can be scheduled in the Currently Active tab.
              </span>
              <accordion>
                <accordion-item
                  v-for="(time) in preferenceValue?.futureStartTimes?.sort((date1, date2) => (
                    date1 > date2 ? 1 : -1))"
                  :key="time"
                  :hide-controls-when-closed="false"
                  @accordionClicked="onAccordionItemClicked(time)"
                >
                  <template
                    #title
                  >
                    <span>
                      {{ formatDate(time) }}
                    </span>
                  </template>
                  <template
                    #controls
                  >
                    <Button
                      v-if="canEditPreferenceValues && !preferenceValue.preferenceType.config"
                      text="Delete"
                      small
                      type="secondary"
                      @click.stop="onAccordionItemDeleteClicked(time)"
                    />
                  </template>
                  <template
                    v-if="preferenceValue.allValues && preferenceValue.allValues[time]"
                    #content
                  >
                    <preference-value
                      :preference-value-d-t-o="preferenceValueDTO(
                        preferenceValue.preferenceType.type,
                        preferenceValue.preferenceType.baseType,
                        !!preferenceValue.inherited,
                        preferenceValue.allValues[time],
                        time,
                        null,
                        null,
                        preferenceValue.preferenceType.customEditor,
                        preferenceValue.preferenceName,
                      )"
                      :is-future-value="true"
                      :can-edit-preference-values="!preferenceValue.preferenceType.config && canEditPreferenceValues"
                      @saveRequest="onSaveRequest"
                      @hasChange="updateHasChanges"
                    />
                    <div
                      v-if="canEditPreferenceValues && preferenceValue.preferenceType.config"
                    >
                      Editing for this type is not yet available. It can still be done in the old solution (DFPAdmin).
                    </div>
                  </template>
                </accordion-item>
              </accordion>
            </div>
            <div class="preference__surroundings">
              <div
                v-if="preferenceValue?.overridden"
                class="preference__overridden"
              >
                <div class="preference__label-container">
                  <span>Overridden in</span>&nbsp;
                </div>
                <div class="preference__overridden-container">
                  <div
                    v-if="!showMoreOverridden"
                  >
                    <div
                      v-for="(item, index) in preferenceValue.overridden.slice(0,5)"
                      :key="index"
                      class="preference__overridden_value"
                    >
                      <span>
                        {{ item.hierarchyNodeName }}
                        ({{ item.hierarchyNodeCode }})
                      </span>
                    </div>
                    <div
                      v-if="preferenceValue.overridden.length > 5"
                      class="preference__overridden_value"
                      @click="onShowMoreClicked"
                    >
                      <span
                        class="preference__overridden_show-more"
                      >
                        Show more...
                      </span>
                    </div>
                  </div>
                  <div
                    v-else
                  >
                    <div
                      v-for="(item, index) in preferenceValue.overridden"
                      :key="index"
                      class="preference__overridden_value"
                    >
                      <span>
                        {{ item.hierarchyNodeName }}
                        ({{ item.hierarchyNodeCode }})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :title="'Please Confirm'"
      :enabled="isChangeModalEnabled"
      :primary-button-text="'Confirm'"
      :secondary-button-text="'Cancel'"
      :info="'You have made changes that have not been saved.'"
      @secondaryClicked="onChangeModalCancelClicked"
      @primaryClicked="onChangeModalConfirmClicked"
    />
    <modal
      :title="'Are you sure?'"
      :enabled="isDeleteModalEnabled"
      :primary-button-text="'Delete'"
      :secondary-button-text="'Cancel'"
      :styles="{type: 'danger'}"
      @secondaryClicked="onDeleteModalCancelClicked"
      @primaryClicked="onDeleteModalConfirmClicked"
    >
      This will delete the scheduled change for <b>{{ formatDate(futureValueToDelete) }}</b>. This cannot be undone.
    </modal>
    <modal
      :title="'Info'"
      :enabled="isInfoModalEnabled"
      :primary-button-text="'Close'"
      :data-array="infoModalArray"
      @primaryClicked="isInfoModalEnabled=false"
    />
    <modal
      :enabled="isErrorModalEnabled"
      :secondary-button-text="'Okay'"
      :styles="{ secondaryType: 'primary' }"
      title="Something went wrong"
      info="It looks like something went wrong. Please try again later."
      @secondaryClicked="isErrorModalEnabled=false"
    />
  </div>
</template>

<script>
import {
  computed, onUpdated, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import PreferenceValue from '@/components/domain/preferences/preference-value.vue';
import Tabs from '@/components/shared/tabs.vue';
import Accordion from '@/components/shared/accordion.vue';
import AccordionItem from '@/components/shared/accordion-item.vue';
import Modal from '@/components/shared/modal.vue';

import { useDto } from '@/composables/dto';

import { useUtilsStore } from '@/store/utils';
import { useUserEvaluationStore } from '@/store/user-evaluation';
import { usePreferenceManagementStore } from '@/store/preference-management';

import Button from '@ingka/button-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

import { storeToRefs } from 'pinia';

export default {
  components: {
    PreferenceValue,
    Tabs,
    Accordion,
    AccordionItem,
    Modal,
    Button,
  },

  async setup() {
    onUpdated(() => console.log('preference updated'));

    const route = useRoute();

    const {
      preferenceValue,
      preferenceValueChanges,
    } = storeToRefs(usePreferenceManagementStore());

    const {
      queryPreferenceValue,
      updatePreferenceValue,
      createPreferenceValue,
      clearPreferenceValue,
      fetchPreferenceValueList,
      fetchFuturePreferenceValue,
      storePreferenceValueChanges,
    } = usePreferenceManagementStore();

    const {
      hasProductWritePrivilege,
      hasPreferenceWritePrivilege,
    } = storeToRefs(useUserEvaluationStore());

    const {
      preferenceValueDTO,
      preferenceValueDTOtoPayload,
    } = useDto();

    const {
      toastRef,
      formatDate,
    } = useUtilsStore();

    const {
      offset,
      limit,
    } = storeToRefs(useUtilsStore());

    const router = useRouter();

    const backRoute = {
      name: 'preferences',
      query: {
        productCode: route.query.productCode,
        hierarchy: route.query.hierarchy,
        hierarchyCode: route.query.hierarchyCode,
        hierarchyNode: route.query.hierarchyNode,
        hierarchyNodeCode: route.query.hierarchyNodeCode,
        hierarchyNodeType: route.query.hierarchyNodeType,
      },
    };

    const changes = [];
    storePreferenceValueChanges(0);
    const canEditPreferenceValues = computed(
      () => {
        console.log('edit rights', hasProductWritePrivilege.value, hasPreferenceWritePrivilege.value);
        return hasProductWritePrivilege.value || hasPreferenceWritePrivilege.value;
      },
    );

    const infoModalArray = [
      { key: 'Preference', value: preferenceValue.value?.preferenceCode },
      { key: 'Type', value: preferenceValue.value?.preferenceType.preferenceTypeCode },
      { key: 'Privilege', value: preferenceValue.value?.privilegeCode },
    ];
    const isDeleteModalEnabled = ref(false);

    const tabs = [
      { id: 'tab_0', name: 'Currently active' },
      { id: 'tab_1', name: 'Future changes' },
    ];
    const activeTab = ref(tabs[0]);

    if (route.query.preferenceTab) {
      const urlTab = tabs.filter((tab) => tab.name === route.query.preferenceTab);
      // eslint-disable-next-line prefer-destructuring
      activeTab.value = urlTab[0];
    }

    const onAccordionItemClicked = async (item) => {
      const payload = {
        productCode: route.query.productCode,
        hierarchyCode: route.query.hierarchyCode,
        preferenceCode: route.query.preferenceCode,
        ...((route.query.hierarchyCode || preferenceValue.value.hierarchyCode) && {
          hierarchyCode: route.query.hierarchyCode || preferenceValue.value.hierarchyCode,
          hierarchyNodeCode: route.query.hierarchyNodeCode || 'ROOT',
          hierarchyNodeType: route.query.hierarchyNodeType || 'ROOTNODE',
        }),
        startTime: item,
      };
      await fetchFuturePreferenceValue(payload);
    };
    const isInfoModalEnabled = ref(false);

    const onInfoClicked = () => {
      isInfoModalEnabled.value = true;
    };

    const isChangeModalEnabled = ref(false);

    const modalCallback = ref(null);

    const changeTab = (tab) => {
      if (preferenceValueChanges.value > 0) {
        isChangeModalEnabled.value = true;
        modalCallback.value = () => {
          if (activeTab.value.id === tabs[0].id) {
            // eslint-disable-next-line prefer-destructuring
            activeTab.value = tabs[1];
          } else {
            // eslint-disable-next-line prefer-destructuring
            activeTab.value = tabs[0];
          }
          router.push({
            query: {
              ...route.query,
              preferenceTab: activeTab.value.name,
            },
          });
        };
      } else {
        activeTab.value = tab;
        router.push({
          query: {
            ...route.query,
            preferenceTab: tab.name,
          },
        });
      }
    };

    const onChangeModalCancelClicked = () => {
      isChangeModalEnabled.value = false;
    };

    const onChangeModalConfirmClicked = () => {
      isChangeModalEnabled.value = false;
      if (modalCallback.value) {
        modalCallback.value();
      }
    };

    const isErrorModalEnabled = ref(false);

    const triggerToast = (toastContent) => {
      toastRef.value.toastAnimation(toastContent);
    };

    const onSaveRequest = async (action, payload) => {
      console.log('onSaveRequest, action, payload', action, payload);
      if (action === 'create') {
        console.log('create');
        const success = await createPreferenceValue(preferenceValueDTOtoPayload(payload, preferenceValue.value));
        if (!success) {
          isErrorModalEnabled.value = true;
        } else {
          triggerToast({
            message: 'Preference value created.',
          });
        }
      } else if (action === 'scheduled') {
        console.log('scheduled');
        const success = await createPreferenceValue(preferenceValueDTOtoPayload(payload, preferenceValue.value));
        if (!success) {
          isErrorModalEnabled.value = true;
        } else {
          triggerToast({
            message: 'Preference value(s) scheduled successfully.',
          });
        }
      } else if (action === 'update') {
        console.log('update');
        const success = await updatePreferenceValue(preferenceValueDTOtoPayload(payload, preferenceValue.value));
        if (!success) {
          isErrorModalEnabled.value = true;
        } else {
          triggerToast({
            message: 'Preference value(s) updated.',
          });
        }
      } else if (action === 'clear') {
        console.log('clear');
        const success = await clearPreferenceValue(preferenceValueDTOtoPayload(payload, preferenceValue.value));
        if (!success) {
          isErrorModalEnabled.value = true;
        } else {
          triggerToast({
            message: 'Preference value(s) deleted.',
          });
        }
      }

      await fetchPreferenceValueList({
        productCode: route.query.productCode,
        hierarchyCode: route.query.hierarchyCode,
        ...((route.query.hierarchyCode) && {
          hierarchyCode: route.query.hierarchyCode,
          hierarchyNodeCode: route.query.hierarchyNodeCode || 'ROOT',
          hierarchyNodeType: route.query.hierarchyNodeType || 'ROOTNODE',
        }),
        offset: offset.value,
        limit: limit.value,
      });

      const queriedPref = queryPreferenceValue(
        {
          preferenceCode: preferenceValue.value.preferenceCode,
          productCode: preferenceValue.value.productCode,
        },
      );

      if (payload.startTime === preferenceValue.value.startTime || !preferenceValue.value.startTime) {
        preferenceValue.value.allValues[payload.startTime] = (!payload.values.length && queriedPref.inherited?.values)
          || payload.values;
      } else {
        preferenceValue.value.allValues[preferenceValue.value.startTime] = queriedPref
          .allValues[preferenceValue.value.startTime];
      }

      console.log('preference', preferenceValue.value.allValues[payload.startTime], payload.values);
      // A preference value that is 'saved' with en empty .values array means it was deleted, so we need to delete it from our data too.
      if (preferenceValue.value.startTime !== payload.startTime && (!payload.values || !payload.values.length)) {
        delete preferenceValue.value.allValues[payload.startTime];
        preferenceValue.value.futureStartTimes
          .splice(preferenceValue.value.futureStartTimes.indexOf(payload.startTime), 1);
      }
      preferenceValue.value.lastUpdated = queriedPref.lastUpdated;
      preferenceValue.value.inherited = queriedPref.inherited;
      preferenceValue.value.overridden = queriedPref.overridden;
      preferenceValue.value.futureStartTimes = queriedPref.futureStartTimes;
      preferenceValue.value.startTime = queriedPref.startTime;
    };

    const showMoreOverridden = ref(false);

    const onShowMoreClicked = () => {
      showMoreOverridden.value = true;
    };

    // TODO good solution?
    const futureValueToDelete = ref('');
    const onAccordionItemDeleteClicked = (time) => {
      isDeleteModalEnabled.value = true;
      futureValueToDelete.value = time;
    };

    const onDeleteModalCancelClicked = () => {
      isDeleteModalEnabled.value = false;
    };

    const onDeleteModalConfirmClicked = () => {
      isDeleteModalEnabled.value = false;
      const payload = {
        productCode: route.query.productCode,
        hierarchyCode: route.query.hierarchyCode,
        preferenceCode: route.query.preferenceCode,
        ...((route.query.hierarchyCode) && {
          hierarchyCode: route.query.hierarchyCode,
          hierarchyNodeCode: route.query.hierarchyNodeCode || 'ROOT',
          hierarchyNodeType: route.query.hierarchyNodeType || 'ROOTNODE',
        }),
        startTime: futureValueToDelete.value,
      };
      clearPreferenceValue(payload);
      const index = preferenceValue.value.futureStartTimes.indexOf(futureValueToDelete.value);
      if (index > -1) {
        preferenceValue.value.futureStartTimes.splice(index, 1);
      }
    };

    watch(preferenceValue, () => console.log('preferenceValue changes'));

    const updateHasChanges = (change) => {
      storePreferenceValueChanges(preferenceValueChanges.value + change);
      console.log('preferenceValueChanges, change', preferenceValueChanges.value, change);
    };

    const onBackClicked = () => {
      modalCallback.value = () => router.push(backRoute);
    };

    watch(modalCallback, () => {
      if (preferenceValueChanges.value > 0) {
        isChangeModalEnabled.value = true;
      } else {
        modalCallback.value();
      }
    });

    return {
      formatDate,
      futureValueToDelete,
      preferenceValue,
      onAccordionItemClicked,
      backRoute,
      isDevMode: process.env.NODE_ENV !== 'production',
      showMoreOverridden,
      onShowMoreClicked,
      changeTab,
      onSaveRequest,
      tabs,
      activeTab,
      changes,
      preferenceValueDTO,
      canEditPreferenceValues,
      onAccordionItemDeleteClicked,
      updateHasChanges,
      isChangeModalEnabled,
      isDeleteModalEnabled,
      onChangeModalCancelClicked,
      onChangeModalConfirmClicked,
      onDeleteModalCancelClicked,
      onDeleteModalConfirmClicked,
      isInfoModalEnabled,
      onInfoClicked,
      infoModalArray,
      onBackClicked,
      isErrorModalEnabled,
      triggerToast,
      ellipsis: Icons.ellipsis,
    };
  },
};
</script>

<style scoped>
.preference {
  width: 100%;
  background-color: var(--colour-bg-default);
}

.preference__back-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.preference__back-link:hover {
  cursor: pointer;
}

.preference__back-link::before {
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  content: '';
  background-image: url("../../../assets/svg/arrow-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.preference__tabs {
  margin-bottom: 1.625rem;
}

.preference__values {
  display: block;
}

.preference__title {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.preference__title-button {
  display: flex;
  justify-content: space-between;
}

.preference__node-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.preference__description {
  padding-bottom: 3rem;
  color: var(--colour-text-default);
}

.preference__values-wrapper {
  display: block;
  padding-top: 1rem;
}

.preference__surroundings {
  max-width: 18rem;
}

.preference__surroundings > div {
  display: block;
  margin-bottom: 0.375rem;
}

.preference__label-container {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  white-space: nowrap;
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.preference__label-container > span:first-child {
  font-weight: bold;
}

.preference__label-container::before {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  content: '';
  background-repeat: no-repeat;
  background-size: contain;
}

.preference__inherited {
  font-weight: bold;
}

.preference__overridden .preference__label-container::before {
  background-image: url("../../../assets/svg/overridden.svg");
}

.preference__overridden_value {
  padding-top: 0.875rem;
}

.preference__overridden_show-more:hover {
  color: var(--colour-text-dark);
  cursor: pointer;
}

@media (min-width: 46.375rem) {
  .preference {
    max-width: 79rem;
  }

  .preference__tab-content {
    min-width: 30rem;
  }
}

@media (min-width: 76rem) {
  .preference__values-wrapper {
    display: flex;
  }

  .preference__surroundings {
    padding-left: 4.375rem;
  }

  .preference__tab-content {
    min-width: 50rem;
  }

  .preference__label-container {
    padding-top: 0;
  }

  .preference__tabs-wrapper {
    width: 50rem;
  }
}
</style>
