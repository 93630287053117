<template>
  <div>
    <InlineMessage
      v-if="newPreferenceValue.customEditor === 'RtfEditor'"
      version="cautionary"
      title="Warning"
      body="Viewing an RTF file in-app is not guaranteed to display correctly due to version and editor differences.
      The data that is saved is unchanged."
      :dismissable="false"
    />
    <component
      :is="getComponent"
      v-if="newPreferenceValue"
      :preference-value-d-t-o="newPreferenceValue"
    />
    <div
      v-if="canEditPreferenceValues"
      class="preference-value__bottom-section"
    >
      <div
        v-if="!isFutureValue"
      >
        <label for="datetime-picker">Schedule changes (UTC)</label>
      </div>
      <div class="preference_value__bottom-controls">
        <div
          class="preference-value__bottom-datepicker"
        >
          <Datepicker
            ref="datepickerRef"
            v-model="datepickerData.value"
            :placeholder="isFutureValue ? formatDateISO(preferenceValueDTO.startTime) : 'Now'"
            :action-row-component="DatepickerActionRow"
            :format="datepickerFormat"
            :min-date="datepickerData.minimum"
            :max-date="datepickerData.maximum"
            :year-range="[new Date().getFullYear(), new Date().getFullYear() + 10]"
            :start-time="{
              hours: datepickerData.minimum.getHours(),
              minutes: datepickerData.minimum.getMinutes()
            }"
            :minutes-increment="5"
            :custom-props="datepickerData"
            :time-picker-component="CustomTimePicker"
            :month-change-on-scroll="false"
            @closed="onDatepickerClosed()"
          >
            <template #now-button="{ selectCurrentDate }">
              <span
                title="Select current date"
                @click="selectCurrentDate()"
              >
                <button
                  type="button"
                  role="button"
                  class="btn btn--secondary"
                  @click="onNowClicked()"
                >
                  <span
                    class="btn__inner cancel__button preference_value__dont-schedule-button"
                  >
                    <span
                      class="btn__label cancel__button-label"
                    >
                      Don't schedule
                    </span>
                  </span>
                </button>
              </span>
            </template>
          </Datepicker>
        </div>
        <div
          class="preference-value__buttons"
        >
          <Button
            :disabled="!isSaveButtonEnabled"
            text="Cancel"
            small
            fluid
            type="secondary"
            @click="onCancelClicked"
          />
          <Button
            :disabled="!isSaveButtonEnabled"
            text="Save"
            small
            fluid
            type="primary"
            @click="onSaveClicked"
          />
        </div>
      </div>
    </div>
    <modal
      :enabled="isModalEnabled"
      :secondary-button-text="'Cancel'"
      v-bind="modalVersions[version]"
      @secondaryClicked="onModalCancelClicked"
      @primaryClicked="onModalConfirmClicked"
    >
      <div
        v-if="useModalTemplate"
      >
        Saving without any values will <b>delete</b> the scheduled change.
      </div>
    </modal>
  </div>
</template>

<script>
/* eslint-disable max-len */
import {
  computed, defineAsyncComponent, ref, reactive, nextTick, watch, toRefs, onBeforeUnmount, onUnmounted,
} from 'vue';
import { useRoute } from 'vue-router';
import { cloneDeep, isEqual } from 'lodash';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import CustomTimePicker from '@/components/shared/datepicker-timepicker.vue';
import DatepickerActionRow from '@/components/shared/datepicker-action-row.vue';
import Modal from '@/components/shared/modal.vue';
import InlineMessage from '@/components/shared/inline-message.vue';

import { useUtilsStore } from '@/store/utils';
import { usePreferenceManagementStore } from '@/store/preference-management';

import Button from '@ingka/button-vue';

export default {
  components: {
    Button,
    Datepicker,
    Modal,
    InlineMessage,
    PreferenceTypeSingle: defineAsyncComponent(() => import(
      '@/components/domain/preferences/view/preference-type-single.vue'
    )),
    PreferenceTypeSingleEdit: defineAsyncComponent(() => import(
      '@/components/domain/preferences/edit/preference-type-single.vue'
    )),
    PreferenceTypeCollection: defineAsyncComponent(() => import(
      '@/components/domain/preferences/view/preference-type-collection.vue'
    )),
    PreferenceTypeCollectionEdit: defineAsyncComponent(() => import(
      '@/components/domain/preferences/edit/preference-type-collection.vue'
    )),
    PreferenceTypeDictionary: defineAsyncComponent(() => import(
      '@/components/domain/preferences/view/preference-type-dictionary.vue'
    )),
    PreferenceTypeDictionaryEdit: defineAsyncComponent(() => import(
      '@/components/domain/preferences/edit/preference-type-dictionary.vue'
    )),
  },

  props: {
    canEditPreferenceValues: {
      type: Boolean,
      default: false,
    },
    preferenceValueDTO: {
      type: Object,
      required: true,
    },
    isFutureValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['saveRequest', 'hasChange'],

  async setup(props, { emit }) {
    // Composables

    const { canEditPreferenceValues, preferenceValueDTO } = toRefs(props);

    const {
      getPreferenceValueTypeComponent,
    } = usePreferenceManagementStore();

    const {
      formatDateISO,
    } = useUtilsStore();

    // Variable declarations

    const route = useRoute();
    const isSaveButtonEnabled = ref(false);
    const hasInvalidValues = ref(false);

    const backRoute = {
      name: 'preferences',
      query: {
        productCode: route.query.productCode,
        hierarchy: route.query.hierarchy,
        hierarchyCode: route.query.hierarchyCode,
        hierarchyNode: route.query.hierarchyNode,
        hierarchyNodeCode: route.query.hierarchyNodeCode,
        hierarchyNodeType: route.query.hierarchyNodeType,
      },
    };

    const newPreferenceValue = ref(undefined);
    const isModalEnabled = ref(false);
    const datepickerRef = ref(null);

    // Function declarations

    const hasEmptyValues = (values) => values?.filter((value) => (!String(value.value).trim())).length > 0;
    const hasEmptyOrDuplicateKeys = (keyValuePairs) => {
      const keyMap = keyValuePairs.map((value) => value.key.toLowerCase());

      // TODO: Optimize.
      return keyValuePairs.filter(
        (value) => {
          const lowerCaseKey = value.key.toLowerCase();
          // returns true if same key occurs more than once or key is empty.
          return (keyMap.indexOf(lowerCaseKey) !== keyMap.lastIndexOf(lowerCaseKey))
            || !lowerCaseKey.trim();
        },
        // length > 0 means there were duplicate key(s) or empty key(s)
      ).length > 0;
    };

    const addZeroIfNeeded = (number) => (number < 10 ? `0${number}` : `${number}`);
    const dateAsUTC = (date) => date && new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const dateAsOriginal = (date) => date && new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

    const datepickerData = reactive({
      minimum: dateAsUTC(new Date()),
      value: null,
      maximum: dateAsUTC(new Date('2030-12-31T23:59')),
    });
    const datepickerFormat = (date) => `${date.getFullYear()}-${addZeroIfNeeded(date.getMonth() + 1)}-${addZeroIfNeeded(date.getDate())}, ${addZeroIfNeeded(date.getHours())}:${addZeroIfNeeded(date.getMinutes())}`;

    const setModalVisibility = (value) => { isModalEnabled.value = value; };

    const resetPreferenceValue = () => {
      console.log('resetPreferenceValue');
      newPreferenceValue.value = reactive(cloneDeep(preferenceValueDTO.value));
      datepickerData.value = null;
    };

    // Watchers
    watch(() => preferenceValueDTO.value.values, () => {
      console.log('watch preferenceValueDTO');
      resetPreferenceValue();
    }, { immediate: true });

    watch(() => newPreferenceValue, () => {
      hasInvalidValues.value = false;
      const origValues = preferenceValueDTO.inherited ? [] : preferenceValueDTO.value.values; // isValueInherited.value ? [] : preferenceValueDTO.value.values;
      const newValues = newPreferenceValue.value.values;
      console.log('watch newPreferenceValue changed (new, old)', newValues, origValues);

      isSaveButtonEnabled.value = !(
        hasEmptyValues(newValues)
        || isEqual(newValues, origValues)
        || (newPreferenceValue.value.type === 'Dictionary' && hasEmptyOrDuplicateKeys(newValues))
        || (newPreferenceValue.value.isInherited && !newPreferenceValue.value.values.length)
      );
    },
    { deep: true });

    watch(isSaveButtonEnabled, () => {
      emit('hasChange', isSaveButtonEnabled.value ? 1 : -1);
    });

    onBeforeUnmount(() => {
      // If save button is enabled, that means we've send a hasChange emit with '1'.
      // So we need to send a '-1' to compensate for it before component gets destroyed.
      if (isSaveButtonEnabled.value) {
        emit('hasChange', -1);
      }
    });

    // Event Handlers

    const onDatepickerClosed = async () => {
      await nextTick();
      datepickerData.value = datepickerData.value < dateAsUTC(new Date()) ? null : datepickerData.value;
    };

    const onNowClicked = () => {
      datepickerRef.value.closeMenu();
      datepickerRef.value.clearValue();
    };

    const onModalConfirmClicked = async () => {
      const thereWasAnOldValue = !!preferenceValueDTO.value.values.length && !preferenceValueDTO.value.isInherited;
      const thereWasNoOldValue = !thereWasAnOldValue;

      const thereAreChanges = !!newPreferenceValue.value.values.length;
      const allValuesWereRemoved = !thereAreChanges;

      const isNotScheduled = datepickerData.value === null;
      const isScheduled = !isNotScheduled;

      const dateToSave = dateAsOriginal(datepickerData.value);
      // eslint-disable-next-line no-nested-ternary
      const getStartTime = () => (dateToSave === null ? (thereWasAnOldValue ? preferenceValueDTO.value.startTime : `${new Date().toISOString().slice(0, -5)}Z`) : `${dateToSave.toISOString().slice(0, -5)}Z`);
      const payload = { ...newPreferenceValue.value, startTime: getStartTime() };

      if (thereWasAnOldValue && thereAreChanges && isNotScheduled) {
        emit('saveRequest', 'update', payload);
      } else if (thereWasNoOldValue && thereAreChanges && isNotScheduled) {
        emit('saveRequest', 'create', payload);
      } else if (thereWasNoOldValue && thereAreChanges && isScheduled) {
        emit('saveRequest', 'scheduled', payload);
      } else if (thereWasAnOldValue && thereAreChanges && isScheduled) {
        emit('saveRequest', 'scheduled', payload);
      } else if (thereWasAnOldValue && allValuesWereRemoved && isNotScheduled) {
        emit('saveRequest', 'clear', payload);
      } else if (thereWasAnOldValue && allValuesWereRemoved && isScheduled) {
        console.log('Scheduling removal of preference value is not yet supported by backend.');
      }

      setModalVisibility(false);
      isSaveButtonEnabled.value = false;
    };

    const useModalTemplate = ref(false);

    const modalVersions = {
      delete:
        {
          title: 'Sure you want to continue?',
          primaryButtonText: 'Delete',
          templateString: undefined,
          styles: { type: 'danger' },
        },
      changes:
        {
          title: 'Please Confirm',
          primaryButtonText: 'Confirm',
          templateString: 'You have made changes that will be saved for this value.',
          styles: { type: 'primary' },
        },
      notAvailable:
        {
          title: 'Warning',
          primaryButtonText: '',
          secondaryButtonText: 'Okay',
          templateString: 'Scheduling removal of values is not supported. ',
          styles: { secondaryType: 'primary' },
        },
    };
    const version = ref('changes');

    const onSaveClicked = () => {
      if (!newPreferenceValue.value.values.length && datepickerData.value) {
        version.value = 'notAvailable';
        useModalTemplate.value = false;
      } else if (!newPreferenceValue.value.values.length) {
        version.value = 'delete';
        useModalTemplate.value = true;
      } else {
        version.value = 'changes';
        useModalTemplate.value = false;
      }
      setModalVisibility(true);
    };

    const onCancelClicked = resetPreferenceValue;

    const onModalCancelClicked = () => {
      setModalVisibility(false);
    };

    onUnmounted(() => console.log('Unmounted'));

    // Getters

    const getComponent = computed(() => {
      const type = getPreferenceValueTypeComponent(
        props.preferenceValueDTO.type,
      );
      return `${type}${canEditPreferenceValues.value ? '-edit' : ''}`;
    });

    return {
      version,
      modalVersions,
      useModalTemplate,
      backRoute,
      formatDateISO,
      dateAsUTC,
      getComponent,
      datepickerRef,
      datepickerData,
      datepickerFormat,
      CustomTimePicker,
      DatepickerActionRow,
      newPreferenceValue,

      isModalEnabled,
      hasInvalidValues,
      isSaveButtonEnabled,

      onNowClicked,
      onSaveClicked,
      onCancelClicked,
      onDatepickerClosed,
      onModalConfirmClicked,
      onModalCancelClicked,
    };
  },
};
</script>

<style scoped>
.preference-value__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 21.5rem;
}

.preference-value__bottom-datepicker {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.preference-value__bottom-section {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.preference-value__buttons > .btn--primary {
  margin-left: 1rem;
}

.preference-value__buttons > a {
  text-decoration: none;
}

.preference_value__bottom-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.preference_value__dont-schedule-button {
  max-height: 2rem;
}
</style>
