<template>
  <div class="custom-time-picker-component">
    <div>
      <select
        class="select-input select-input-left"
        onfocus="this.size=5;"
        onblur="this.size=1;"
        onchange="this.size=1; this.blur();"
        :value="hModel"
        @change="$emit('update:hours', +$event.target.value)"
      >
        <option
          v-for="h in hoursArray"
          :key="h.value"
          :value="h.value"
        >
          {{ h.text }}
        </option>
      </select>
      <span class="select-input-colon">
        :
      </span>
      <select
        class="select-input select-input-right"
        onfocus="this.size=5;"
        onblur="this.size=1;"
        onchange="this.size=1; this.blur();"
        :value="mModel"
        @change="$emit('update:minutes', +$event.target.value)"
      >
        <option
          v-for="m in minutesArray"
          :key="m.value"
          :value="m.value"
        >
          {{ m.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default ({
  props: {
    hoursIncrement: { type: [Number, String], default: 1 },
    minutesIncrement: { type: [Number, String], default: 5 },
    is24: { type: Boolean, default: true },
    hoursGridIncrement: { type: [String, Number], default: 1 },
    minutesGridIncrement: { type: [String, Number], default: 5 },
    range: { type: Boolean, default: false },
    filters: { type: Object, default: () => ({}) },
    minTime: { type: Object, default: () => ({}) },
    maxTime: { type: Object, default: () => ({}) },
    timePicker: { type: Boolean, default: false },
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    customProps: { type: Object, default: null },
  },
  emits: ['update:hours', 'update:minutes'],

  setup(props) {
    const mModel = computed(
      () => (Math.ceil(props.minutes / props.minutesIncrement) * props.minutesIncrement),
    );
    const hModel = computed(
      () => (Math.ceil(props.hours / props.hoursIncrement) * props.hoursIncrement),
    );

    // Generate array of hours
    const hoursArray = computed(() => {
      const arr = [];
      for (let i = 0; i < 24; i += 1) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    });

    // Generate array of minutes
    const minutesArray = computed(() => {
      const arr = [];
      for (let i = 0; i < 60; i += props.minutesIncrement) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    });

    return {
      hoursArray,
      minutesArray,
      /* onWheelMinutes,
      onWheelHours, */
      mModel,
      hModel,
    };
  },
});
</script>

<style scoped>
.custom-time-picker-component {
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
}

.select-input-colon {
  font-size: 1.5em;
  line-height: 1em;
  vertical-align: middle;
}

.select-input-left {
  margin-left: -2em;
}

.select-input-right {
  margin-left: 0;
}

.select-input {
  position: absolute;
  font-size: 1.5em;
  border: none;
  outline: none;
}

.select-input:focus {
  margin-top: -5.5em;
  margin-left: -1.75em;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  background: var(--colour-bg-default);
  border: 0.0625rem solid var(--colour-border-light);
  border-top: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.select-input-right:focus {
  margin-left: 0;
}

.select-input:focus::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}
</style>
