/* eslint-disable import/prefer-default-export */
import { usePayloader } from '@/composables/payloader';
import { reactive, unref } from 'vue';

export const useDto = () => {
  // TODO: Sätt startTime, newStartTime, values, newValues sist
  const preferenceValueDTO = (
    type,
    baseType,
    isInherited,
    values,
    startTime,
    newValues,
    newStartTime,
    customEditor,
    name,
  ) => (
    reactive({
      type,
      baseType,
      startTime,
      values,
      isInherited,
      newValues,
      newStartTime,
      customEditor,
      name,
    }));

  const preferenceValueDTOtoPayload = (dto, preferenceValue) => usePayloader()
    .payloadEditPreferenceValue(
      preferenceValue, unref(dto).startTime, unref(dto).values,
    );

  return {
    preferenceValueDTO,
    preferenceValueDTOtoPayload,
  };
};
