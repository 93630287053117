import { reactive, unref } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const usePayloader = () => {
  const payloadEditPreferenceValue = (prefValue, startTime, values) => {
    const unrefd = unref(prefValue);
    return reactive({
      productCode: unrefd.productCode,
      preferenceCode: unrefd.preferenceCode,
      hierarchyCode: unrefd.hierarchyCode,
      hierarchyNodeCode: unrefd.hierarchyNodeCode,
      hierarchyNodeType: unrefd.hierarchyNodeType,
      startTime,
      values,
    });
  };

  return {
    payloadEditPreferenceValue,
  };
};
