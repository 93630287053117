<template>
  <div
    aria-hidden="false"
    :class="['inline-message', 'inline-message--' + version,
             subtle && 'inline-message--subtle', closed && 'inline-message--closed' ]"
  >
    <svg
      v-if="version === 'cautionary'"
      focusable="false"
      viewBox="0 0 22 21"
      width="21"
      height="21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-icon inline-message__icon"
      aria-hidden="true"
    >
      <path
        d="M10.0036 13.1337H12.0036L12.0036 7.13372H10.0036L10.0036 13.1337Z"
      />
      <path
        d="M11.0036 14.6337C10.3132 14.6337 9.75357 15.1934 9.75357 15.8837C9.75357 16.5741 10.3132 17.1337
          11.0036 17.1337C11.6939 17.1337 12.2536 16.5741 12.2536 15.8837C12.2536 15.1934 11.6939 14.6337 11.0036 14.6337Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.115234 19.418L10.1152 0.417969H11.8851L21.8851 19.418L21.0002 20.8837H1.00015L0.115234 19.418ZM2.65652
          18.8837H19.3438L11.0002 3.03081L2.65652 18.8837Z"
      />
    </svg>
    <svg
      v-else-if="version === 'negative'"
      focusable="false"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-icon inline-message__icon"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 12c0 4.4183-3.5817 8-8 8-1.8487 0-3.551-.6271-4.9056-1.6801L18.3199 7.0944C19.3729 8.449 20 10.1513 20 12zM5.6801 16.9056
          16.9056 5.6801C15.551 4.6271 13.8487 4 12 4c-4.4183 0-8 3.5817-8 8 0 1.8487.627 3.551 1.6801 4.9056zM22 12c0 5.5228-4.4772
          10-10 10-5.5229 0-10-4.4772-10-10C2 6.4771 6.4771 2 12 2c5.5228 0 10 4.4771 10 10z"
      />
    </svg>
    <svg
      v-else-if="version === 'positive'"
      focusable="false"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-icon inline-message__icon"
      aria-hidden="true"
    >
      <path
        d="M18 8.9608 16.5591 7.5l-5.9955 6.0784-3.1227-3.166L6 11.8732 10.5636 16.5 18 8.9608z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 22c5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10zm0-2c4.4183
          0 8-3.5817 8-8s-3.5817-8-8-8-8 3.5817-8 8 3.5817 8 8 8z"
      />
    </svg>
    <svg
      v-else
      focusable="false"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-icon inline-message__icon"
      aria-hidden="true"
    >
      <path
        d="M10 7.5C10.6904 7.5 11.25 6.94036 11.25 6.25C11.25 5.55964 10.6904 5 10 5C9.30964 5 8.75 5.55964
          8.75 6.25C8.75 6.94036 9.30964 7.5 10 7.5Z"
      />
      <path
        d="M9 9V15H11V9H9Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0
          10 0ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
      />
    </svg>
    <div>
      <p
        class="inline-message__title"
      >
        {{ title }}
      </p>
      <span
        class="inline-message__body"
      >
        {{ body }}
        <a
          :href="link"
          class="link"
        >
          {{ linkLabel }}
        </a>
      </span>
      <button
        v-if="dismissable"
        type="button"
        class="btn btn--small btn--xsmall btn--icon-tertiary inline-message__close-button"
        @click="closed = true"
      >
        <span
          class="btn__inner"
        >
          <svg
            focusable="false"
            viewBox="0 0 24 24"
            class="svg-icon btn__icon"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="m12.0006 13.4148 2.8283 2.8283 1.4142-1.4142-2.8283-2.8283 2.8283-2.8283-1.4142-1.4142-2.8283 2.8283L9.172 7.7578 7.7578
                9.172l2.8286 2.8286-2.8286 2.8285 1.4142 1.4143 2.8286-2.8286z"
            />
          </svg>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    version: {
      type: String,
      default: 'informative',
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    subtle: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    linkLabel: {
      type: String,
      default: '',
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const closed = ref(false);

    return {
      closed,
    };
  },
};
</script>

<style>
.inline-message {
  margin-bottom: 1rem;
}

.inline-message--closed {
  height: 0;
  margin-bottom: 0;
}
</style>
