<template>
  <div>
    <div class="custom-action-row">
      <p class="current-selection">
        {{ date.utc }}
      </p>
    </div>
    <div class="custom-action-row-buttons">
      <button
        type="button"
        class="btn btn--tertiary btn-padding btn--small"
        @click="$emit('closePicker')"
      >
        <span class="btn__inner action-row-button">
          <span class="btn__label"> Cancel </span>
        </span>
      </button>
      <button
        type="button"
        class="btn btn--emphasised btn-padding btn--small"
        @click="$emit('selectDate')"
      >
        <span class="btn__inner action-row-button">
          <span class="btn__label"> Use this UTC </span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    selectText: { type: String, default: 'Select' },
    cancelText: { type: String, default: 'Cancel' },
    internalModelValue: { type: [Date, Array], default: null },
    range: { type: Boolean, default: false },
    previewFormat: {
      type: [String, Function],
      default: () => '',
    },
    monthPicker: { type: Boolean, default: false },
    timePicker: { type: Boolean, default: false },
  },
  emits: ['selectDate', 'closePicker'],
  setup(props) {
    const displayDate = computed(() => {
      const addZeroIfNeeded = (number) => (number < 10 ? `0${number}` : `${number}`);
      if (props.internalModelValue) {
        const date = props.internalModelValue;
        return {
          // eslint-disable-next-line max-len
          utc: `UTC: ${date.getFullYear()}-${addZeroIfNeeded(date.getMonth() + 1)}-${addZeroIfNeeded(date.getDate())}, ${addZeroIfNeeded(date.getHours())}:${addZeroIfNeeded(date.getMinutes())}`,
        };
      }
      return '';
    });

    return {
      date: displayDate,
    };
  },
});
</script>

<style scoped>
  .custom-action-row {
    z-index: 40;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn-padding {
    padding: 0.5em;
  }

  .custom-action-row-buttons {
    z-index: 30;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .current-selection {
    margin: 10px 0 0 0;
  }

  .select-button {
    display: block;
    padding: 5px;
    margin: 10px;
    color: var(--dp-success-color);
    cursor: pointer;
    background: transparent;
    border: 1px solid var(--dp-success-color);
    border-radius: 4px;
  }

  .action-row-button {
    height: 2rem;
    min-height: 2rem;
    padding: 0 1rem;
    border-radius: 2rem;
  }
</style>
