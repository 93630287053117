<template>
  <li
    ref="element"
    class="accordion__item"
  >
    <div
      role="heading"
    >
      <button
        type="button"
        class="accordion__heading accordion-item-header"
        aria-expanded="false"
        @click="onAccordionClicked(item)"
      >
        <span
          class="accordion-item-header__title-wrapper"
        >
          <div
            ref="title"
            class="accordion-item-header__title"
          >
            <slot
              name="title"
            />
          </div>
          <div
            v-show="expanded || !hideControlsWhenClosed"
            class="accordion-item-header__controls"
            @mouseover="onMouseOverControls"
            @mouseleave="onMouseLeaveControls"
          >
            <slot
              name="controls"
            />
          </div>
        </span>
        <div
          ref="accordionIcon"
          focusable="false"
          viewBox="0 0 24 24"
          class="svg-icon accordion-item-header__icon"
          aria-hidden="true"
        />
      </button>
    </div>
    <div
      class="accordion__content"
      role="region"
    >
      <slot name="content" />
    </div>
  </li>
</template>

<script>
import { ref } from 'vue';

import { useUtilsStore } from '@/store/utils';

export default {
  props: {
    hideControlsWhenClosed: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['accordionClicked', 'accordionExpanded'],

  setup(props, { emit }) {
    const { toHash, formatDate } = useUtilsStore();

    const element = ref();
    const expanded = ref(false);
    const accordionIcon = ref();
    const title = ref();

    const onAccordionClicked = (item) => {
      element.value.classList.toggle('accordion__item--active');
      expanded.value = !expanded.value;
      accordionIcon.value.classList.toggle('svg-icon--rotate-180');
      emit('accordionClicked', item);
      if (expanded.value) emit('accordionExpanded', item);
    };

    const onMouseOverControls = () => {
      title.value.classList.add('accordion_item__no_decoration');
    };

    const onMouseLeaveControls = () => {
      title.value.classList.remove('accordion_item__no_decoration');
    };

    return {
      onAccordionClicked,
      onMouseOverControls,
      onMouseLeaveControls,
      toHash,
      formatDate,
      title,
      element,
      expanded,
      accordionIcon,
    };
  },
};
</script>

<style scoped>
  .accordion-item-header__icon {
    width: 1rem;
    height: 1rem;
    content: '';
    background: transparent;
    background-image: url("../../assets/svg/chevron-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: inherit;
  }

  .accordion-item-header__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .accordion_item__no_decoration {
    text-decoration: none !important;
  }
</style>

<style>
  .accordion-item-header__controls > * {
    margin-right: 1rem;
  }
</style>
